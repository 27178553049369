<template>
	<div class="main-gallery">
		<div class="container">
			<div class="main-gallery__top">
				<div class="main-gallery__title">
					<h2 class="main-gallery__hdng">
						{{ title }}
					</h2>
					<span v-if="subTitle" class="main-gallery__sub-title">
						{{ subTitle }}
					</span>
				</div>
				<div
					v-if="articlesCount > breakpoint?.itemsToShow"
					class="main-gallery__btns"
				>
					<button
						type="button"
						@click="prev"
						aria-label="gallery prev btn"
						class="btn main-gallery__btn main-gallery__btn--prev"
					>
						<tk-svg type="gallery" />
					</button>
					<button
						type="button"
						@click="next"
						aria-label="gallery next btn"
						class="btn main-gallery__btn main-gallery__btn--next"
					>
						<tk-svg type="gallery" />
					</button>
				</div>
			</div>

			<div class="main-gallery__content">
				<slot>
					<tk-carousel
						ref="carousel"
						class="main-gallery__carousel"
						v-if="content.length"
						v-model="currentSlide"
						:settings="carousel.settings"
						:breakpoints="carousel.breakpoints"
						:wrapAround="true"
					>
						<tk-slide v-for="item in content" :key="item.id">
							<router-link :to="item.link" v-if="item.link">
								<component :type="typeCard" :content="item" :is="card" />
							</router-link>
							<component :type="typeCard" :content="item" :is="card" v-else />
						</tk-slide>
					</tk-carousel>
				</slot>
			</div>
		</div>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
	name: 'Gallery',
	components: {
		MainCard: defineAsyncComponent(() =>
			import('@/components/views/home/cards/Main')
		),
		ArticleCard: defineAsyncComponent(() =>
			import('@/components/views/home/cards/Article')
		),
		ReviewCard: defineAsyncComponent(() =>
			import('@/components/views/home/cards/Review')
		),
		AboutCard: defineAsyncComponent(() =>
			import('@/components/views/home/cards/About')
		)
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		subTitle: {
			type: String,
			default: ''
		},
		content: {
			type: Array,
			default: () => []
		},
		meta: {
			type: Object,
			default: () => ({
				total: 0
			})
		},
		carousel: {
			type: Object,
			default: () => ({
				settings: {},
				breakpoints: {}
			})
		},
		card: {
			type: String,
			default: 'MainCard'
		},
		typeCard: {
			type: String,
			default: 'big'
		}
	},
	watch: {
		currentSlide: function () {
			if (
				this.currentSlide + this.$refs.carousel?.data.config.itemsToShow >=
				this.content.length
			) {
				// конец слайдов
				this.$emit('more', true)
			}
		}
	},
	data() {
		return {
			currentSlide: 0,
			breakpoint: {
				itemsToShow: 3
			}
		}
	},
	computed: {
		articlesCount() {
			return this.meta.total
		},
		isDisabledNextButton() {
			return (
				this.currentSlide ===
				this.content.length - this.$refs.carousel?.data.config.itemsToShow
			)
		},
		isDisabledPrevButton() {
			return this.currentSlide < 1
		}
	},
	methods: {
		prev() {
			this.$refs.carousel?.prev()
		},
		next() {
			this.$refs.carousel?.next()
		},
		computeBreakpoint() {
			const screenWidth = window.document.body.clientWidth
			let maxBreakpointWidth = '0'
			for (let width in this.carousel.breakpoints) {
				if (
					parseInt(maxBreakpointWidth) < parseInt(width) &&
					parseInt(width) <= screenWidth
				) {
					maxBreakpointWidth = width
				}
			}
			this.breakpoint = this.carousel.breakpoints[maxBreakpointWidth]
		}
	},
	mounted() {
		this.computeBreakpoint()
		window.addEventListener('resize', this.computeBreakpoint, { passive: true })
	},
	unmounted() {
		window.removeEventListener('resize', this.computeBreakpoint, {
			passive: true
		})
	}
}
</script>

<style lang="sass">
.main-gallery
  &__top
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    gap: 20px

  &__hdng
    margin: 0
    font-weight: 700
    font-size: 24px
    line-height: 28px
    color: #2A3043

  &__sub-title
    display: inline-block
    margin-top: 10px
    font-size: 14px
    line-height: 16px
    color: #AFAFAF

  &__btns
    display: flex
    gap: 5px

  &__btn
    width: 32px
    height: 32px
    cursor: pointer

    &--prev
      transform: rotate(180deg)

    &:disabled svg path
      fill: #E4E4E4

  &__content
    margin-top: 40px
    display: flex
    justify-content: space-between
    gap: 20px
    overflow: auto

    &::-webkit-scrollbar
      width: 0px
      height: 0px
    &::-webkit-scrollbar-track
      background: transparent
    &::-webkit-scrollbar-thumb
      background: transparent
    &::-webkit-scrollbar-thumb:hover
      background: transparent

    .carousel__slide a
      width: 100%

  &__carousel
    width: 100%

  @media screen and (max-width: 700px)
    &__btns
      display: none
</style>
